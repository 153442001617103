import React from "react";

export default function HowitWork() {
  return (
    <>
      <section className="job-categories section_padding_top section_padding_bottom">
        <div className="auto-container">
          <div className="text-center wow fadeInUp">
            <div className="sec-title">
              {/* <h2>How it works?<span className="SectionTitle"></span></h2> */}
              <h2>How it works?</h2>
              {/* <div className="text">Job for anyone, anywhere</div> */}
            </div>
          </div>
          <div className="row wow fadeInUp">
            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative before-content">
                  <div className="icon position-relative">
                    <span className="fal fa-book-user"></span>
                    <span className="tag">01</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Register an account <br className="d-none d-lg-block" /> to
                    start
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative after-content">
                  <div className="icon position-relative">
                    <span className="far fa-file-magnifying-glass"></span>
                    <span className="tag">02</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Explore over thousands <br className="d-none d-lg-block" />{" "}
                    of resumes
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative">
                  <div className="icon position-relative">
                    <span className="far fa-clipboard-list-check"></span>
                    <span className="tag">03</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Find the most suitable <br className="d-none d-lg-block" />{" "}
                    candidate.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
