import React from "react";
import ServiceCat1 from "./ServiceCat1";
import ServiceCat2 from "./ServiceCat2";
import ServiceCat3 from "./ServiceCat3";
import PageMetaData from "../PageMetaData";
import PageBanner2 from "../PageBanner2";

export default function Services() {
  // console.log(location.pathname);
  return (
    <>
      <PageMetaData PageId="4" />
      <PageBanner2 PageId="4" />
      {/* <PageBradcrume Pagename="Services" pagetitle="services" /> */}
      <ServiceCat1 />
      <ServiceCat2 bgColor={"bgc-home20"} />
      <ServiceCat3 />
    </>
  );
}
