import React from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import WebsiteSetting, { DetailsBlog } from "../FetchAPI";
import WhyChoose from "../WhyChoose/WhyChoose";
import HowitWork from "../HowItWork/HowitWork";

export default function BlogDetails() {
  let { slug } = useParams();
  const [Blogdetails] = DetailsBlog(slug);
  const [webSetting] = WebsiteSetting();
  // console.log(Blogdetails);

  const showBanner = () => {
    let AddData = "";
    if (Blogdetails.upload_type === "0") {
      AddData = (
        <div className="blog_iframe">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${Blogdetails.bannervideo}`}
            title="Name Change Service in India | Name Change Agent in India"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen=""
          ></iframe>
        </div>
      );
    } else if (Blogdetails.upload_type === "1") {
      AddData = (
        <img
          src={Blogdetails.bannerimage}
          alt={Blogdetails.imgalttag}
          title={Blogdetails.heading}
          className="bannerimg"
        />
      );
    } else {
      AddData = (
        <div className="banner_desc">
          <div className="BannerDsceBox">
            <h2
              dangerouslySetInnerHTML={{
                __html: Blogdetails.bannerdesc,
              }}
            ></h2>
          </div>
        </div>
      );
    }
    return AddData;
  };

  return (
    <>
      <Helmet>
        <title>{Blogdetails.metatitle}</title>
        <meta name="description" content={Blogdetails.metadesc} />
        <meta name="keywords" content={Blogdetails.metakeyword} />
        <link rel="canonical" href={window.location.href} />

        {/* {Blogdetails.custom_script && (
          <script type="application/ld+json">
            {Blogdetails.custom_script}
          </script>
        )}

        {Blogdetails.custom_script2 && (
          <script type="application/ld+json">
            {Blogdetails.custom_script2}
          </script>
        )} */}
      </Helmet>

      <section className="PageBanner_section">{showBanner()}</section>

      <section className="section_padding blogDetails_section section_padding_bottom">
        <div className="auto-container">
          <div className="row">
            <div className="col-lg-4 ml-2 BlogTopForm">
              <div className="site_bar_form">
                <h4 className="form_heading">GET IN TOUCH</h4>
                <div className="siteForm" id="siteForm">
                  <iframe
                    className="ifram_form_id"
                    width="100%"
                    height="450"
                    src="https://manage.yourdoorstep.co/forms/ticket"
                    frameBorder="0"
                    allowFullScreen
                    title="form"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="blog_content">
                <article id="post-87" className="single single-post">
                  <div className="post-header">
                    <p className="post-categories">{Blogdetails.category}</p>
                    <h1 className="post-title">{Blogdetails.heading}</h1>
                    <div className="post-meta">
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 mt-3">
                          {/* <span className="post-meta-date">
                            {Blogdetails.date}
                          </span> */}
                          <span className="post-meta-date">
                            {Blogdetails.author && (
                              <i
                                className="fa fa-user bogdetailsicon"
                                aria-hidden="true"
                              ></i>
                            )}
                            {Blogdetails.author}
                          </span>

                          <span className="post-meta-date blogdetailsDate">
                            {Blogdetails.create_date && (
                              <i
                                className="fa fa-calendar bogdetailsicon"
                                aria-hidden="true"
                              ></i>
                            )}
                            {Blogdetails.create_date}
                          </span>
                        </div>
                        <div className="col-lg-6 col-md-12 Post_comm_shere mt-3">
                          <span className="post-comments">
                            <span className="fa fw fa-comment"></span>12
                            <span className="resp"> Comments</span>
                          </span>
                          <div className="share-post">
                            <Link
                              className="fb_share"
                              onClick={() => {
                                window.open(
                                  "https://www.facebook.com/sharer/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="facebook"
                            >
                              <i
                                className="fa fa-facebook"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              className="fb_twitter"
                              onClick={() => {
                                window.open(
                                  "https://twitter.com/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="Twitter"
                            >
                              <i
                                className="fa fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </Link>
                            <Link
                              to={
                                Blogdetails.blog_type === "0"
                                  ? `https://api.whatsapp.com/send?text=${slug} - ${webSetting.base_url}${slug}`
                                  : `https://api.whatsapp.com/send?text=${slug} - ${webSetting.base_url}${Blogdetails.language}/${slug}`
                              }
                              className="fb_whatsapp"
                              title="Whatsapp"
                              rel="nofollow noopener"
                              target="_blank"
                            >
                              <i
                                className="fa fa-whatsapp"
                                aria-hidden="true"
                              ></i>
                            </Link>

                            <Link
                              className="fb_linkedin"
                              onClick={() => {
                                window.open(
                                  "http://www.linkedin.com/shareArticle/",
                                  "Share This Post",
                                  "width=640,height=450"
                                );
                              }}
                              title="linkedin"
                            >
                              <i
                                className="fa fa-linkedin"
                                aria-hidden="true"
                              ></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="postflex">
                    <div className="post-inner">
                      <div
                        className="post-content entry-content"
                        dangerouslySetInnerHTML={{
                          __html: Blogdetails.body,
                        }}
                      ></div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-lg-4 ml-2 BlogSiteForm">
              <div className="site_bar_form">
                <h4 className="form_heading">GET IN TOUCH</h4>
                <div className="siteForm" id="siteForm">
                  <iframe
                    className="ifram_form_id"
                    width="100%"
                    height="450"
                    src="https://manage.yourdoorstep.co/forms/ticket"
                    frameBorder="0"
                    allowFullScreen
                    title="form"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How it Work  */}
      <HowitWork />
      {/* End How it works? */}

      {/* Why Chhose us start */}
      <WhyChoose />
      {/* Why Chhose us End */}
    </>
  );
}
