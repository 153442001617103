import React from "react";
import ServiceBox from "./ServiceBox";
import { ServicesSection2 } from "../FetchAPI";

export default function ServiceCat2({bgColor}) {
//  console.log(ServicesSection2()) ;
const [serCatData2, ser_section2] = ServicesSection2();

  
  return (
    <>
      {/* Services Categories  2*/}
      <section className={`job-categories section_padding_top section_padding_bottom ${bgColor}`}>
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>{serCatData2.category}</h2>
          </div>
          <div className="row wow fadeInUp">    
            {ser_section2 &&
              ser_section2.map((value, index) => (
                <ServiceBox
                  key={index}
                  title={value.title}
                  slug_url={value.slugurl}
                  icon_image={value.icon_image} 
                />
              ))}
          </div>
        </div>
      </section>
      {/* Services 2 End */}
    </>
  );
}
