import React from "react";
import { Helmet } from "react-helmet";
import {AllPagesDatas} from "./FetchAPI";

export default function PageMetaData({PageId}) {
  const [AllpageData] = AllPagesDatas(PageId);
  return (
    <>
      <Helmet>
      <title>{AllpageData.metatitle}</title>
        <meta name="description" content={AllpageData.metadesc1} />
        <meta name="keywords" content={AllpageData.metakeyword} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    </>
  );
}
