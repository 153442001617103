import React from 'react'

export default function HeaderProfileMenu({Logout}) {
  return (
     <>
     {/* Dashboard Option */}
            <div className="dropdown dashboard-option">
              <a href='/' className="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                {/* <img src="images/resource/company-6.png" alt="avatar" className="thumb"/> */}
                <img src="images/resource/candidate-1.png" alt="User Profile" title="User" className="thumb"/>
                <span className="name">My Profile</span>
              </a>  
              <ul className="dropdown-menu Profile_Ul">
                <li className="active"><a href="/"> <i className="la la-home"></i> Dashboard</a></li>
                {/* <li><a href="dashboard-company-profile.html"><i className="la la-user-tie"></i>Company Profile</a></li> */}
                {/* <li><a href="dashboard-post-job.html"><i className="la la-paper-plane"></i>Post a New Job</a></li> */}
                {/* <li><a href="dashboard-manage-job.html"><i className="la la-briefcase"></i> Manage Jobs </a></li> */}
                {/* <li><a href="dashboard-applicants.html"><i className="la la-file-invoice"></i> All Applicants</a></li> */}
                {/* <li><a href="dashboard-resumes.html"><i className="la la-bookmark-o"></i>Shortlisted Resumes</a></li> */}
                {/* <li><a href="dashboard-packages.html"><i className="la la-box"></i>Packages</a></li> */}
                {/* <li><a href="dashboard-messages.html"><i className="la la-comment-o"></i>Messages</a></li> */}
                {/* <li><a href="dashboard-resume-alerts.html"><i className="la la-bell"></i>Resume Alerts</a></li> */}
                {/* <li><a href="dashboard-change-password.html"><i className="la la-lock"></i>Change Password</a></li> */}
                <li><a href="/"><i className="la la-user-alt"></i>View Profile</a></li>
                <li><button onClick={Logout}><i className="la la-sign-out"></i>Logout</button></li>
                {/* <li><a href="index.html"><i className="la la-trash"></i>Delete Profile</a></li> */}
              </ul>
            </div>
     </>
  )
} 
