import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Login from "./Component/Login-Signup/Login";
import Signup from "./Component/Login-Signup/Signup";
import Home from "./Component/Home";
import Blog from "./Component/Blog/Blog";
import BlogDetails from "./Component/Blog/BlogDetails";
import ScrollToTop from "./Component/ScrollToTop";
import { ReactNotifications } from "react-notifications-component";
import Footer from "./Component/Main/Footer";
import Services from "./Component/Service/Services";
import ServiceDetails from "./Component/Service/ServiceDetails";
import ContactUS from "./Component/ContactUS";
import About from "./Component/About";
import Header from "./Component/Main/Header";
import Blogs from "./Component/Blog/Blogs";
import Enquery from "./Component/Service/Enquiry";
import RefundPolicy from "./Component/TermsPrivacyPage/RefundPolicy";
import TermCondition from "./Component/TermsPrivacyPage/TermCondition";
import PrivacyPolicy from "./Component/TermsPrivacyPage/PrivacyPolicy";
import ProductServiceDescription from "./Component/TermsPrivacyPage/ProductServiceDescription";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <ReactNotifications />  
        <Header/>
        <Routes>
          <Route exact="true" path="/" element={<Home />} />
          <Route exact="true" path="/blog" element={<Blog />} />
          <Route exact="true" path="/blogs/:language" element={<Blogs />} />
          <Route exact="true" path="/contact" element={<ContactUS />} />
          <Route exact="true" path="/about" element={<About/>} />
          <Route exact="true" path="/services" element={<Services />} />
          <Route  
            exact="true"
            path="/services/:serviceSlug"
            element={<ServiceDetails />}
          />
          <Route exact="true" path="/enquiry/:planid" element={<Enquery />} />
          <Route exact="true" path="/:slug" element={<BlogDetails />} />
          <Route exact="true" path="/:lang/:slug" element={<BlogDetails />} />

          <Route exact="true" path="/login" element={<Login />} />
          <Route exact="true" path="/register" element={<Signup />} />
          <Route exact="true" path="/term-and-condition" element={<TermCondition />} />
          <Route exact="true" path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact="true" path="/cancellation-refund-policy" element={<RefundPolicy />} />
          <Route exact="true" path="/product-service-description" element={<ProductServiceDescription />} />
          <Route exact="true" path="/*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
