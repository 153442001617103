import React from "react";
import { Link } from "react-router-dom";
import WebsiteSetting, { FootServices } from "../FetchAPI";
import ScrollToTop from "react-scroll-to-top";

export default function Footer() {
  const [webSetting] = WebsiteSetting();
  const [ServiceFoot] = FootServices();
  return (
    <>
      <footer className="main-footer style-two">
        <div className="auto-container">
          <div className="widgets-section">
            <div className="row">
              <div className="big-column col-xl-4 col-lg-3 col-md-12">
                <div className="footer-column about-widget footerLogo_about">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={webSetting.white_logo}
                        alt=""
                        style={{ width: "160px" }}
                      />
                    </Link>
                  </div>
                  <p className="phone-num">
                    <span>Call us : </span>
                    <Link to={`tel:${webSetting.mobile_number}`}>
                      <i className="fa fa-phone"></i> {webSetting.mobile_number}
                    </Link>
                  </p>
                  <p className="address">
                    <i className="fa fa-map-marker"></i>
                    {webSetting.address}
                  </p>
                  <p className="address">
                    <i className="fa fa-envelope"></i>
                    <a href={`mailto: ${webSetting.email}`} className="email">
                      {webSetting.email}
                    </a>
                  </p>
                </div>
              </div>

              <div className="big-column col-xl-8 col-lg-9 col-md-12">
                <div className="row">
                  <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">Services</h4>
                      <div className="widget-content">
                        <ul className="list">
                          {ServiceFoot &&
                            ServiceFoot.map((value, index) => (
                              <li key={index}>
                                <Link to="/services">{value.title}</Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-5 col-md-6 col-sm-12">
                    <div className="footer-widget links-widget">
                      <h4 className="widget-title">
                        Frequently Asked Questions
                      </h4>
                      <div className="widget-content d-flex">
                        <ul className="list">
                          <li>
                            <Link to="/privacy-policy">Privacy & Securty</Link>
                          </li>
                          <li>
                            <Link to="/term-and-condition">
                              Terms of Service
                            </Link>
                          </li>
                          <li>
                            <Link to="/product-service-description">
                              Product Service <br />
                              Description
                            </Link>
                          </li>
                          <li>
                            <Link to="/cancellation-refund-policy">
                              Cancellation Refund Policy
                            </Link>
                          </li>
                        </ul>

                        <ul className="list">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="/about">About</Link>
                          </li>
                          <li>
                            <Link to="/services">Services</Link>
                          </li>
                          <li>
                            <Link to="/blog">Blogs</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-column col-lg-4 col-md-12 col-sm-12">
                    <div className="footer-widget">
                      <h4 className="widget-title">Join Us On</h4>
                      <div className="widget-content">
                        <div className="newsletter-form">
                          <div className="text text-white">
                            We don’t send spam so don’t worry.
                          </div>
                          <form method="post" action="#" id="subscribe-form">
                            <div className="form-group">
                              <div className="response"></div>
                            </div>
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="email"
                                defaultValue="eamil"
                                placeholder="Email"
                                required
                              />
                              <button
                                type="button"
                                id="subscribe-newslatters"
                                className="theme-btn"
                              >
                                <i className="flaticon-envelope"></i>
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="social-links">
                          <Link to={webSetting.facebook}>
                            <i className="fa fa-facebook-f"></i>
                          </Link>
                          <Link to={webSetting.youtube}>
                            <i className="fa fa-youtube"></i>
                          </Link>
                          <Link to={webSetting.twitter}>
                            <i className="fab fa-twitter"></i>
                          </Link>
                          <Link to={webSetting.instagram}>
                            <i className="fab fa-instagram"></i>
                          </Link>
                          <Link to={webSetting.linkedin}>
                            <i className="fab fa-linkedin-in"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="auto-container">
            <div className="outer-box">
              <div className="copyright-text">{webSetting.copyright}</div>
            </div>
          </div>
        </div>
      </footer>

      <a
        href={`https://wa.me/${webSetting.whatsapp}?text=Hi, I would like to speak to a Name Change Service`}
        className="wa-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
      </a>

      <a
        href={`tel:${webSetting.mobile_number}`}
        className="call-float-img-circle"
        target="_blank"
        rel="noreferrer"
      >
        <i className="fa fa-phone" aria-hidden="true"></i>
      </a>

      <ScrollToTop smooth="true" />
    </>
  );
}
