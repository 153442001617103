function Validation(values) {
    let error = {};
    // const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const password_pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

    if (values.number === "") {
      error.number = "Number Should not be Empty!";
    } else {
      error.number = "";
    }
  
    if (values.password === "") {
      error.password = "Password Should not be Empty!";
    } else {
      error.password = "";
    }
  
    return error;
  }
  
  export default Validation;
  