import React from "react";
// import PageBanner from "./PageBanner";
import PageMetaData from "./PageMetaData";
import { AllPagesDatas } from "./FetchAPI";
import PageBanner2 from "./PageBanner2";

export default function About() {
  const [AllpageData] = AllPagesDatas(2);
  return (
    <>
      <PageMetaData PageId="2" />
      <PageBanner2 PageId="2"/>
      <section className="about-section-three">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="column col-lg-5 col-md-6 col-sm-12">
              <figure className="image">
                <img
                  src={AllpageData.image}
                  alt=""
                  className="w-100 rounded"
                />
              </figure>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="text-box">
                <h4>{AllpageData.title && AllpageData.title}</h4>
                <p  dangerouslySetInnerHTML={{
              __html: AllpageData.description1,
            }}></p>
                <p  dangerouslySetInnerHTML={{
              __html: AllpageData.description1,
            }}></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How it Work  */}
      <section className="job-categories  section_padding_bottom">
        <div className="auto-container">
          <div className="text-center wow fadeInUp">
            <div className="sec-title">
              <h2>How it works?</h2>
              {/* <div className="text">Job for anyone, anywhere</div> */}
            </div>
          </div>
          <div className="row wow fadeInUp">
            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative before-content">
                  <div className="icon position-relative">
                    <span className="fal fa-book-user"></span>
                    <span className="tag">01</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Register an account <br className="d-none d-lg-block" /> to
                    start
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative after-content">
                  <div className="icon position-relative">
                    <span className="far fa-file-magnifying-glass"></span>
                    <span className="tag">02</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Explore over thousands <br className="d-none d-lg-block" />{" "}
                    of resumes
                  </h4>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-6">
              <div className="how-it-work text-center">
                <div className="top-part position-relative">
                  <div className="icon position-relative">
                    <span className="far fa-clipboard-list-check"></span>
                    <span className="tag">03</span>
                  </div>
                </div>
                <div className="details">
                  <h4 className="title">
                    Find the most suitable <br className="d-none d-lg-block" />{" "}
                    candidate.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End How it works? */}
    </>
  );
}
