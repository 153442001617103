import React from "react";
import ServiceBox from "./ServiceBox";
import { ServicesSection1 } from "../FetchAPI";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ServiceCat1({ bgColor }) {
  const location = useLocation();
  const [serCatData1, ser_section1] = ServicesSection1();
  // console.log(serCatData1[0].id);

  function ShowviewMore() {
    let showView = "";
    if (location.pathname === "/services") {
      showView = "";
    } else {
      showView = (
        <div className="category-block-two at-home20 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
          <NavLink to={`/services`}>
            <div className="inner-box">
              <div className="content serviceContent">
                {/* <span className="icon flaticon-money-1"></span> */}
                <div className="imageiconBox">
                  <img src="images/more.png" alt="" className="serviceIcon" />
                </div>
                <h4>View More</h4>
              </div>
            </div>
          </NavLink>
        </div>
      );
    }
    return showView;
  }

  return (
    <>
      {/* Services Categories 1 */}
      <section
        className={`job-categories section_padding_top section_padding_bottom ${bgColor}`}
      >
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>{serCatData1.category}</h2>
          </div>
          <div className="row wow fadeInUp">
            {ser_section1 &&
              ser_section1.map((value, index) => (
                <ServiceBox
                  key={index}
                  title={value.title}
                  slug_url={value.slugurl}
                  icon_image={value.icon_image}
                />
              ))}
            {ShowviewMore()}
          </div>
        </div>
      </section>
      {/* Services 1 End */}
    </>
  );
}
