import React from "react";
import { NavLink } from "react-router-dom";

export default function ServiceBox(props) {
  return (
    <>
      <div className="category-block-two at-home20 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-6">
        <NavLink to={`/services/${props.slug_url}`}>
          <div className="inner-box">
            <div className="content serviceContent">
              {/* <span className="icon flaticon-money-1"></span> */}
              <div className="imageiconBox">
                <img src={props.icon_image} alt="" className="serviceIcon" />
              </div>
              <h4>{props.title}
              </h4>
            </div>
          </div>
        </NavLink>
      </div>
    </>
  );
}
