import React from "react";
import ServiceBox from "./ServiceBox";
import { ServicesSection3 } from "../FetchAPI";

export default function ServiceCat3({bgColor}) {
  const [serCatData3, ser_section3] = ServicesSection3();

  return (
    <>
      {/* Services Categories  2*/}
      <section className={`job-categories section_padding_top section_padding_bottom ${bgColor}`}>
        <div className="auto-container">
          <div className="sec-title text-center">
            <h2>{serCatData3.category}</h2>
          </div>
          <div className="row wow fadeInUp">    
            {ser_section3 &&
              ser_section3.map((value, index) => (
                <ServiceBox
                  key={index}
                  title={value.title}
                  slug_url={value.slugurl}
                  icon_image={value.icon_image}
                />
              ))}
          </div>
        </div>
      </section>
      {/* Services 2 End */}
    </>
  );
}
