// https://vipin.hyperofficial.in/Master/api/serviceapi/homesetting
// https://vipin.hyperofficial.in/Master/api/serviceapi/homedefaultserviceplan/17
// https://vipin.hyperofficial.in/Master/api/serviceapi/homeplanservice
// https://vipin.hyperofficial.in/Master/api/serviceapi/planservicedetail/$servicsid
// https://vipin.hyperofficial.in/Master/api/settingapi/blogs
// https://vipin.hyperofficial.in/Master/api/settingapi/blogdetail/name-change-procedure-in-india.html
// https://vipin.hyperofficial.in/Master/api/settingapi/alllanguages
// https://vipin.hyperofficial.in/Master/api/settingapi/languageblogs/hi


import { useEffect, useState } from "react";
import axios from "axios";

const Site_url = "https://vipin.hyperofficial.in/";
const Base_url = Site_url + "Master/api/";

/* =======Website Setting=========*/
const WebsiteSetting = () => {
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const Fetchwebsite = async () => {
      await axios.get(`${Base_url}settingapi/website_setting`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setwebSetting(res.data.website_setting);
        }
      });
    };
    Fetchwebsite();
  }, []);
  return [webSetting];
};

/* =======Home homesetting=========*/
const Homesetting = () => { 
  const [SettingHome, setSettingHome] = useState([]);
  useEffect(() => {
    const FetchSettingHome = async () => {
      await axios.get(`${Base_url}serviceapi/homesetting`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setSettingHome(res.data.homesetting);
        }
      });
    };
    FetchSettingHome();
  }, []);
  return [SettingHome];
};

/* =======Home homesetting=========*/
const Homedefaultserviceplan = (defaultServiceid) => {
  const [Defaultservice, setDefaultservice] = useState([]);
  const [Defaultplan, setDefaultplan] = useState([]);
  useEffect(() => {
    const FetchDefaultplan = async () => {
      await axios.get(`${Base_url}serviceapi/homedefaultserviceplan/${defaultServiceid}`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setDefaultservice(res.data.servicedata);
          setDefaultplan(res.data.serviceplan);
        }
      });
    };
    FetchDefaultplan();
  }, [defaultServiceid]);
  return [Defaultservice, Defaultplan];
};
/* =======Home Plans Tabs=========*/
const HomePlanstabs = () => {
  const [PlanTabsHome, setPlanTabsHome] = useState([]);
  useEffect(() => {
    const FetchPlanTabsHome = async () => {
      await axios.get(`${Base_url}serviceapi/homeplanservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setPlanTabsHome(res.data.service);
        }
      });
    };
    FetchPlanTabsHome();
  }, []);
  return [PlanTabsHome];
};
/* =======Home Services Plans=========*/
const HomeServicesPlans = (IDService) => {
  const [HomeServicePlan, setHomeServicePlan] = useState([]);
  useEffect(() => {
    const FetchHomeServicesPlan = async () => {
      await axios.get(`${Base_url}serviceapi/planservicedetail/${IDService}`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setHomeServicePlan(res.data.serviceplan);
        }
      });
    };
    FetchHomeServicesPlan();
  }, [IDService]);
  return [HomeServicePlan];
};

/* =======All Pages Pageslist=========*/
const Allpageslist = () => {
  const [AllPage, setAllPage] = useState([]);  
  useEffect(() => {
    const FetchAllPages = async () => {
      await axios.get(`${Base_url}settingapi/pageslist`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setAllPage(res.data.pageslist);
        }
      });
    };
    FetchAllPages();
  }, []);
  return [AllPage];
};
/* =======All Pages Data/ MetaTags=========*/
const AllPagesDatas = (pageid) => {
  const [AllPageData, setAllPageData] = useState([]);  
  useEffect(() => {
    const FetchAllPagesMeta = async () => {
      await axios.get(`${Base_url}settingapi/pagedata/${pageid}`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setAllPageData(res.data.pagedata);
        }
      });
    };
    FetchAllPagesMeta();
  }, [pageid]);
  return [AllPageData];
};

/* =======Home Blogs=========*/
const HomeBlog = () => {
  const [HomeBlogs, setHomeBlogs] = useState([]);
  const [Loading, setLoading] = useState(false);
  useEffect(() => {
    const FetchHomeBlog = async () => {
      setLoading(true);
      await axios.get(`${Base_url}settingapi/homeblogs`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setHomeBlogs(res.data.blogs);
        }
      });
      setLoading(false);
    };
    FetchHomeBlog();
  }, []);
  return [HomeBlogs,Loading];
};
/* =======BlogPage Blogs=========*/
const BlogPageBlog = () => {
  const [Blogs, setBlogsdata] = useState([]);
  const [Loading, setLoading] = useState(false);
  
  useEffect(() => {
    const FetchBlog = async () => {
      setLoading(true);
      await axios.get(`${Base_url}settingapi/blogs`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setBlogsdata(res.data.blogs);
        }
      });
      setLoading(false);
    };
    FetchBlog();
  }, []);
  return [Blogs,Loading];
};

/* =======BlogPage LangBlog=========*/
const BlogPageLangBlog = (langu) => {
  const [LangBlogs, setLangBlogs] = useState([]);
  const [Loading, setLoading] = useState(false);
  
  useEffect(() => {
    const FetchLangBlog = async () => {
      setLoading(true);
      await axios.get(`${Base_url}settingapi/languageblogs/${langu}`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setLangBlogs(res.data.blogs);
        }
      });
      setLoading(false);
    };
    FetchLangBlog();
  }, [langu]);
  return [LangBlogs,Loading];
};

/* =======Blog All Languages=========*/
const BlogLanguage = () => {
  const [Language, setLanguage] = useState([]);
  useEffect(() => {
    const FetchBlogLanguage = async () => {
      await axios.get(`${Base_url}settingapi/alllanguages`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setLanguage(res.data.alllanguages);
        }
      });
    };
    FetchBlogLanguage();
  }, []);
  return [Language];
};
/* =======Blog Languages MetaTags=========*/
const LanguageMetaTags = (langu) => {
  const [LanguageMeta, setLanguageMeta] = useState([]);
  useEffect(() => {
    const FetchLanguageMeta = async () => {
      await axios.get(`${Base_url}settingapi/alllanguages`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          const resData = res.data.alllanguages;
          const resFilter = resData.filter((langcat) => {
            return langcat.language === langu;
          });
          const jsonLang = JSON.parse(JSON.stringify(resFilter[0])) 
          setLanguageMeta(jsonLang);
        }
      });
    };
    FetchLanguageMeta();
  }, [langu]);
  return [LanguageMeta];
};

/* =======Blog Details=========*/
const DetailsBlog = (slug) => {
  const [Blogdetails, setBlogdetails] = useState([]);
  useEffect(() => {
    const FetchBlogDetails = async () => {
      await axios

        .get(`${Base_url}settingapi/blogdetail/${slug}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setBlogdetails(res.data.blogdetail);
          }
        });
    };
    FetchBlogDetails();
  }, [slug]);
  return [Blogdetails];
};

/* =======Services 1=========*/
const ServicesSection1 = () => {
  const [sersSection1, setsersSection1] = useState([]);
  const [serCatData1, setserCatData1] = useState([]);
  useEffect(() => {
    const FetcServices1 = async () => {
      await axios.get(`${Base_url}serviceapi/servicesection1`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          // console.log(res.data.catdata[0]);
          setserCatData1(res.data.catdata);
          setsersSection1(res.data.service);
          // console.log(res.data.catdata);
        }
      });
    };
    FetcServices1();
  }, []);
  return [serCatData1, sersSection1];
};

/* =======Services 2=========*/
const ServicesSection2 = () => {
  const [sersSection2, setsersSection2] = useState([]);
  const [serCatData2, setserCatData2] = useState([]);
  useEffect(() => {
    const FetcServices2 = async () => {
      await axios.get(`${Base_url}serviceapi/servicesection2`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          // console.log(res.data.catdata[0]);
          setserCatData2(res.data.catdata);
          setsersSection2(res.data.service);
          // console.log(res.data.catdata);
        }
      });
    };
    FetcServices2();
  }, []);
  return [serCatData2, sersSection2];
};

/* =======Services 3=========*/
const ServicesSection3 = () => {
  const [sersSection3, setsersSection3] = useState([]);
  const [serCatData3, setserCatData3] = useState([]);
  useEffect(() => {
    const FetcServices3 = async () => {
      await axios.get(`${Base_url}serviceapi/servicesection3`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          // console.log(res.data.catdata[0]);
          setserCatData3(res.data.catdata);
          setsersSection3(res.data.service);
          // console.log(res.data.catdata);
        }
      });
    };
    FetcServices3();
  }, []);
  return [serCatData3, sersSection3];
};
/* =======All Services=========*/
const AllServices = () => {
  const [ServicesAll, setServicesAll] = useState([]);
  useEffect(() => {
    const FetcAllServices = async () => {
      await axios.get(`${Base_url}serviceapi/allservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicesAll(res.data.service);
        }
      });
    };
    FetcAllServices();
  }, []);
  return [ServicesAll];
};
/* =======Footer Services=========*/
const FootServices = () => {
  const [ServicesFooter, setServicesFooter] = useState([]);
  useEffect(() => {
    const FetcFooterServices = async () => {
      await axios.get(`${Base_url}serviceapi/footerservice`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicesFooter(res.data.service);
        }
      });
    };
    FetcFooterServices();
  }, []);
  return [ServicesFooter];
};
/* =======Services Plans=========*/
const ServicesPlans = () => {
  const [ServicePlan, setServicePlan] = useState([]);
  useEffect(() => {
    const FetchServicesPlan = async () => {
      await axios.get(`${Base_url}serviceapi/serviceplan`).then((res) => {
        if (res.data.status === "1") {
          // alert(res.data.msg);
          setServicePlan(res.data.serviceplan);
        }
      });
    };
    FetchServicesPlan();
  }, []);
  return [ServicePlan];
};

/* =======Services Details=========*/
const ServicesDetails = (serviceSlug) => {
  const [servicedetails, setservicedetails] = useState([]);
  const [detailsServicePlan, setdetailsServicePlan] = useState([]);
  useEffect(() => {
    const FetcServicesDetails = async () => {
      await axios
        .get(`${Base_url}serviceapi/servicedetail/${serviceSlug}`)
        .then((res) => {
          if (res.data.status === "1") {
            // alert(res.data.msg);
            setservicedetails(res.data.servicedetail);
            setdetailsServicePlan(res.data.serviceplan);
          }
        });
    };
    FetcServicesDetails();
  }, [serviceSlug]);
  return [servicedetails,detailsServicePlan];
};

export default WebsiteSetting;
export {
  Homesetting,
  Homedefaultserviceplan,
  HomePlanstabs,
  HomeServicesPlans,
  HomeBlog,
  BlogPageBlog,
  Allpageslist,
  AllPagesDatas,
  BlogPageLangBlog,
  BlogLanguage,
  LanguageMetaTags,
  DetailsBlog,
  ServicesSection1,
  ServicesSection2,
  ServicesSection3,
  AllServices,
  ServicesPlans,
  FootServices,
  ServicesDetails,
};
