import React, { useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import Validation from "./SignupValidation";

export default function Signup() {
  const navigate = useNavigate();
  const notification = {
    // message: "teodosii@react-notifications-component",
    insert: "top",
    container: "bottom-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      showIcon: true,
      onScreen: true,
    },
  };

  const [Data, setData] = useState({
    fname: "",
    email: "",
    password: "",
    number: "",
  });

  const [error, setError] = useState({});

  const handleChange = (e) => {
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(Validation(Data));

    let fdata = new FormData();
    fdata.append("name", Data.fname);
    fdata.append("email", Data.email);
    fdata.append("number", Data.number);
    fdata.append("password", Data.password);
    if (Data.fname && Data.email && Data.number && Data.password) {
      axios
        .post(
          "https://vipin.hyperofficial.in/Master/api/settingapi/userinsert",
          fdata
        )
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "0") {
            // alert(response.data.msg);
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "danger",
            });
          }
          if (response.data.status === "1") {
            Store.addNotification({
              ...notification,
              title: response.data.msg,
              type: "success",
            });
            navigate("/login");
          }
        });
      // .catch((error) => {
      //   alert(error);
      // });
    }
  };

  return (
    <>
      <section className="login-section section_padding_top bgc-home20">
      <div className="login-form default-form login_page_form">
          <div className="form-inner">
            <h3>Sign in to Yourdoostep</h3>

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Full Name : </label>
                <input
                  className="form-control"
                  type="text"
                  name="fname"
                  value={Data.fname}
                  onChange={handleChange}
                  placeholder="Enter Full Name"
                />
                {error.fname && (
                  <span className="text-danger">* {error.fname}</span>
                )}
              </div>
              <div className="form-group">
                <label>Email Id : </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={Data.email}
                  onChange={handleChange}
                  placeholder="Enter Email Address"
                />
                {error.email && (
                  <span className="text-danger">* {error.email}</span>
                )}
              </div>
              <div className="form-group">
                <label>Mobile Number : </label>
                <input
                  className="form-control"
                  type="number"
                  name="number"
                  value={Data.number}
                  onChange={handleChange}
                  placeholder="Enter Mobile Number"
                />
                {error.number && (
                  <span className="text-danger">* {error.number}</span>
                )}
              </div>

              <div className="form-group">
                <label>Password : </label>
                <input
                  id="password-field"
                  type="password"
                  name="password"
                  value={Data.password}
                  onChange={handleChange}
                  placeholder="Enter Unique Password"
                />
                {error.password && (
                  <span className="text-danger">* {error.password}</span>
                )}
              </div>

              <div className="form-group">
                <button
                  className="theme-btn btn-style-one"
                  type="submit"
                  name="submit"
                >
                  Sign In
                </button>
              </div>
            </form>

            <div className="bottom-box">
              <div className="text">
                Allready have an account? <NavLink to="/login">Login</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
