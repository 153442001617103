
import React from 'react';

export default function PageBanner({image,image_alt,image_title}) {

  return (
    <>
    {/* Page Banner Start  */}
    <section className="PageBanner_section">
    <img
        src={image}
        alt={image_alt}
        title={image_title}
        className="bannerimg"
      />
    </section>
   {/* Page Banner End */}
    
    </>
  )
}
