import React from "react";
import WebsiteSetting from "./FetchAPI";
import { Link } from "react-router-dom";
import PageMetaData from "./PageMetaData";
import PageBanner2 from "./PageBanner2";

export default function ContactUS() {
    const [webSetting] = WebsiteSetting();
  return (
    <>
    <PageMetaData PageId="5"/>
    <PageBanner2 PageId="5" />

      <section className="contact-section section_padding_top">
        <div className="auto-container">
          <div className="upper-box">
            <div className="row">
              <div className="contact-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box inner_box_1">
                  <span className="icon">
                    <img src="images/icons/placeholder.svg" alt="" />
                  </span>
                  <h4>Address</h4>
                  <p>
                   {webSetting.address}
                  </p>
                </div>
              </div>
              <div className="contact-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box inner_box_1">
                  <span className="icon">
                    <img src="images/icons/smartphone.svg" alt="" />
                  </span>
                  <h4>Call Us</h4>
                  <p>
                    <Link to={`tel: ${webSetting.mobile_number}`} className="phone">
                    {webSetting.mobile_number}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="contact-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box inner_box_1">
                  <span className="icon">
                    <img src="images/icons/letter.svg" alt="" />
                  </span>
                  <h4>Email</h4>
                  <p>
                    <Link to={`mailto:${webSetting.email}`}>{webSetting.email}</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="contact-form default-form">
                <h3>Leave A Message</h3>

                <form method="post"  id="email-form">
                  <div className="row">
                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                      <div className="response"></div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <label>Your Name</label>
                      <input
                        type="text"
                        name="username"
                        className="username"
                        placeholder="Your Name*"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        name="email"
                        className="email"
                        placeholder="Your Email*"
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <label>Number</label>
                      <input
                        type="number"
                        name="number"
                        className="subject"
                        placeholder="Number *"
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        name="subject"
                        className="subject"
                        placeholder="Subject *"
                        required
                      />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <label>Your Message</label>
                      <textarea
                        name="message"
                        placeholder="Write your message..."
                        required=""
                      ></textarea>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                      <button
                        className="theme-btn btn-style-one"
                        type="button"
                        id="submit"
                        name="submit-form"
                      >
                        Send Massage
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <iframe
                title="Address Location"
                className="map-canvas"
                src={webSetting.map_iframe}
                width="100%"
                height=""
                style={{ border: "0", height: "608px" }}
                allowFullScreen=""
                loading="lazy"
            
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
