import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import WebsiteSetting, { BlogLanguage } from "../FetchAPI";
import HeaderProfileMenu from "../Profile/HeaderProfileMenu";

export default function Header() {
  const [webSetting] = WebsiteSetting();
  const [languagedata] = BlogLanguage();
  // console.log(webSetting);

  const navigate = useNavigate();
  const [auth, setAuth] = useState();
  useEffect(() => {
    const Auth = localStorage.getItem("userid");
    setAuth(Auth);
  }, [auth]);
  // console.log(auth);

  // if (auth === null) {
  //   navigate("/register");
  // }------

  const logout = () => {
    localStorage.removeItem("userid");
    localStorage.clear();
    navigate("/login");
  };


  return (
    <>
      {/* Main Header */}
      {/* <span className="header-span"></span> */}
      <header className="main-header  header-shaddow">
        <div className="container-fluid">
          <div className="main-box">
            <div className="nav-outer">
              <div className="logo-box">
                <div className="logo">
                  <Link to="/">
                    <img
                      src={webSetting.dark_logo}
                      alt=""
                      title=""
                      style={{ width: "160px" }}
                    />
                  </Link>
                </div>
              </div>

              <nav className="nav main-menu">
                <ul className="navigation" id="navbar">
                  <li>
                    <NavLink className="NavLinkActive" exact="true" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="has-mega-menu" id="has-mega-menu">
                    <NavLink className="NavLinkActive" exact="true" to="/about">
                      About
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      className="NavLinkActive"
                      exact="true"
                      to="/services"
                    >
                      Services
                    </NavLink>
                  </li>
                  {/* <li className="">
                    <NavLink className="NavLinkActive" exact="true" to="/blog">
                      Blogs
                    </NavLink>
                  </li> */}
                  <li className="dropdown">
                    <span>
                      <NavLink
                        className="NavLinkActive"
                        exact="true"
                        to="/blog"
                      >
                        Blogs
                      </NavLink>
                    </span>
                    <ul>
                      {languagedata &&
                        languagedata.map((value, index) => (
                          <li key={index}>
                            <NavLink to={`/blogs/${value.language}`}>
                              {value.long_title}
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li className="">
                    <NavLink
                      className="NavLinkActive"
                      exact="true"
                      to="/contact"
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="outer-box">
              {localStorage.getItem("userid") ? (
                <HeaderProfileMenu Logout={logout} />
              ) : (
                <div className="btn-box">
                  <Link
                    to="/login"
                    className="theme-btn btn-style-three call-modal"
                  >
                    Login / Register
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mobile-header">
          <div className="logo">
            {" "}
            <Link to="/">
              <img
                src={webSetting.dark_logo}
                alt=""
                title=""
                style={{ width: "160px" }}
              />
            </Link>
          </div>

          <div className="nav-outer clearfix">
            <div className="outer-box">
              <div className="login-box">
                <a href="/" className="call-modal">
                  <span className="icon-user"></span>
                </a>
              </div>

              <a
                href="#nav-mobile"
                className="mobile-nav-toggler navbar-trigger"
              >
                <span className="flaticon-menu-1"></span>
              </a>
            </div>
          </div>
        </div>

        <div id="nav-mobile"></div>
      </header>
      {/* End Main Header */}
    </>
  );
}
