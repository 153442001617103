function Validation(values) {
    let error = {};
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const password_pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
  
    if (values.fname === "") {
      error.fname = "Name Should not be Empty!";
    } else {
      error.fname = "";
    }
    if (values.email === "") {
      error.email = "Email Should not be Empty!";
    } else if (!email_pattern.test(values.email)) {
      error.email = "Email Did'n Match";
    } else {
      error.email = "";
    } 
  
    // if (values.password === "") {
    //   error.password = "Password Should not be Empty!";
    // } else if (!password_pattern.test(values.password)) {
    //   error.password = "Password Did'n Match";
    // } else {
    //   error.password = "";
    // }
    if (values.password === "") {
      error.password = "Password Should not be Empty!";
    } else {
      error.password = "";
    }
  
    if (values.number === "") {
      error.number = "Number Should not be Empty!";
    } else {
      error.number = "";
    }
  
    return error;
  }
  
  export default Validation;
  