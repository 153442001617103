import React from "react";
import { NavLink } from "react-router-dom";

export default function ServicePlanBox({planid,title,selling_price,price,plan_type,service_tag}) {
    const serviceTag = service_tag.split(",");

  return (
    <>
      <div className="pricing-table col-lg-3 col-md-6 col-sm-12">
        <div className="inner-box">
          <div className="title">{title}</div>
          <div className="price">
            ₹{selling_price} <span className="croosPrice">{price}</span> <span className="duration">/ {plan_type}</span>
          </div>
          <div className="table-content">
            <ul>
                {serviceTag && serviceTag.map((value,index)=>(
                     <li key={index}>
                     <span>{value}</span>
                   </li>
                ))}
            </ul>
          </div>
          <div className="table-footer">
            <NavLink to={`/enquiry/${planid}`} className="theme-btn btn-style-three">
             Book Now
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
